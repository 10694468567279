@font-face {
    font-family: MYRIADPRO-BOLD;
    src: url(../assets/font/MYRIADPRO-BOLD.OTF);
}
@font-face {
    font-family: MYRIADPRO-REGULAR;
    src: url(../assets/font/MYRIADPRO-REGULAR.OTF);
}
html, body, input {
    font-family: MYRIADPRO-BOLD;
}
p, ul {
    font-family: MYRIADPRO-REGULAR;
}