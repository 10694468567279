.Loading {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}
.Loading .spinner-border {
  width: 60px;
  height: 60px;
  border-width: 5px;
}
